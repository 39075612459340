<template>
  <!--Edit-->
  <b-modal id="modal-stats" title="Статистика"  no-body>
    <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
    <div class="p-3">
      <h5>Въпрос "{{pollTitle}}"</h5>
      <ul class="rm-list-borders list-group list-group-flush">
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Общ брой отговорили:</div>
                <div class="widget-subheading">{{totalAnsweredCount}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="rm-list-borders list-group list-group-flush" v-for="answer in answers"
          v-bind:key="answer.id">
        <li class="list-group-item">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="widget-heading">Брой отговорили с "{{answer.title}}":</div>
                <div class="widget-subheading">{{answer.count}}</div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <template #modal-footer="{ cancel }">
      <b-button variant="success" @click="exportPollStats()">
        Експорт
      </b-button>
      <b-button @click="cancel()">
        Затвори
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import PollsService from "../../services/polls.service";

export default {
  components: {
    VueElementLoading
  },
  data: () => ({
    pollId: -1,
    answers: [],
    pollTitle: "",
    totalAnsweredCount: "",
    showLoading: false,
  }),

  methods:
  {
    getPollStats(pollId)
    {
      this.showLoading = true
      this.pollId = pollId;

      PollsService.getPollStats(pollId).then((response) =>
      {
        response

        this.pollTitle = response.data.response.poll_title;
        this.totalAnsweredCount = response.data.response.total_answered;
        this.answers = response.data.response.results;

        this.$root.$emit('bv::toggle::modal', 'modal-stats', '#btnToggle')
      })

      this.showLoading = false
    },

    exportPollStats()
    {
      this.showLoading = true

      PollsService.exportPollStats(this.pollId).then((response) =>
      {
        response

        var type = "text/csv"
        var filename = "codes.csv"

        let blob = new Blob(["\ufeff",response.data], { type: type }),
            url = window.URL.createObjectURL(blob)

        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = filename
        link.click()
      })

      this.showLoading = false
    }
  }
}
</script>