<template>
  <div>
    <page-title :heading=heading :subheading="subheading" :icon=icon></page-title>
    <div class="content">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10" >
          <div class="main-card mb-lg-4 card">
            <div class="card-body">
              <VueElementLoading :active="showLoading" spinner="ring" color="var(--primary)" />
              <p class="m-4" v-if="polls.data.length === 0">Няма намерени резултати</p>
              <div v-else>
                <ul class="todo-list-wrapper list-group list-group-flush" v-for="poll in polls.data" v-bind:key="poll.id">
                  <li class="list-group-item">
                    <div class="widget-content p-0">
                      <div class="todo-indicator" v-bind:class="getStatusColor(poll.status)">
                      </div>
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left ml-3">
                          <div class="widget-heading">
                            {{poll.title}}
                          </div>
                          <div class="widget-subheading">
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Начало: {{ formatFullDate(offsetDate(poll.start)) }}
                            </h6>
                            <h6 class="font-size-md font-weight-normal mb-1">
                              Край: {{ formatFullDate(offsetDate(poll.end)) }}
                            </h6>
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <div class="d-inline-block">
                            <b-dropdown toggle-class="btn-icon btn-icon-only" right variant="link" no-caret>
                              <span slot="button-content">
                                <i class="pe-7s-menu btn-icon-wrapper"></i>
                              </span>
                              <ul class="nav flex-column">
                                <li class="nav-item">
                                  <a href="javascript:void(0);" class="nav-link" style="color: var(--primary)"
                                     v-on:click="$refs.PollStatistics.getPollStats(poll.id)">
                                    <font-awesome-icon icon="poll" class="mr-1"/>
                                    Резултати
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <router-link :to="{ name: 'event-poll-answers-standalone-column', params: { pollId: poll.id } }"
                                               class="nav-link" style="color: var(--primary)">
                                    <font-awesome-icon icon="poll" class="mr-1"/>
                                    Резултати - Columns
                                  </router-link>
                                </li>
                                <li class="nav-item">
                                  <router-link :to="{ name: 'event-poll-answers-standalone-donut', params: { pollId: poll.id } }"
                                               class="nav-link" style="color: var(--primary)">
                                    <font-awesome-icon icon="poll" class="mr-1"/>
                                    Резултати - Donut
                                  </router-link>
                                </li>
                              </ul>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-block text-right card-footer">
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-pagination v-if="polls.total>0"
                  id="pagination"
                  align="center"
                  :total-rows="polls.total"
                  @input="changePage()"
                  v-model="currentPage"
                  :per-page="polls.per_page">
    </b-pagination>
    <poll-statistics ref="PollStatistics"/>
  </div>
</template>


<script>
import PageTitle from "../../Layout/Components/PageTitleWithBackButton.vue";
import VueElementLoading from "vue-element-loading";
import PollsService from "../../services/polls.service";
import swal from "sweetalert";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faEdit,
  faLanguage,
  faListAlt,
  faPoll
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import CountryFlag from "vue-country-flag";
import PollStatistics from './PollStatistics';
import DateHelper from "../../util/dateHelper";

library.add(
    faEdit,
    faLanguage,
    faListAlt,
    faPoll
);

export default {
  components: {
    PageTitle,
    VueElementLoading,
    'font-awesome-icon': FontAwesomeIcon,
    CountryFlag,
    PollStatistics
  },
  mixins: [DateHelper],
  data: () => ({
    TARGET_TYPE_EVENT: 0,
    TARGET_TYPE_PANEL: 1,

    currentUser: {},

    heading: 'Гласуване',
    subheading:"",
    icon: 'pe-7s-graph3 icon-gradient bg-happy-itmeo',

    showLoading: false,

    eventId: -1,
    targetId: -1,
    targetType: -1,

    polls: {
      data: []
    },
    currentPage: 1,
    languages: [],
    answersLink: "",

    statusColors:[
      'bg-secondary',
      'bg-success',
      'bg-danger'
    ]
  }),

  mounted() {
    this.targetId = this.$route.params.targetId;

    if(this.$route.name === "event-polls") {
      this.targetType = this.TARGET_TYPE_EVENT;
      this.answersLink = 'event-poll-answers';
      this.getEventPolls()
    }
    else if(this.$route.name === "panel-polls") {
      this.eventId = this.$route.params.eventId;
      this.targetType = this.TARGET_TYPE_PANEL;
      this.answersLink = 'panel-poll-answers'
      this.getPanelPolls()
    }
  },

  methods:
  {
    refreshList()
    {
      this.currentPage = 1;

      if(this.targetType === this.TARGET_TYPE_EVENT) {
        this.getEventPolls()
      }
      else if(this.targetType === this.TARGET_TYPE_PANEL) {
        this.getPanelPolls()
      }
    },

    refreshCurrentPage()
    {
      if(this.targetType === this.TARGET_TYPE_EVENT) {
        this.getEventPolls()
      }
      else if(this.targetType === this.TARGET_TYPE_PANEL) {
        this.getPanelPolls()
      }
    },

    getEventPolls() {
      this.showLoading = true;

      PollsService.getEventPolls(this.targetId, this.currentPage).then((response) =>
      { response
        var eventTitle = response.data.response.event_title;
        this.subheading = "Събитие \"" + eventTitle + "\"";
        this.polls = response.data.response.results;
        this.showLoading = false;
      },
      error => {
        this.showLoading = false;
        this.handleError(error);
      })
    },

    getPanelPolls() {
      this.showLoading = true;

      PollsService.getPanelPolls(this.targetId, this.currentPage).then((response) =>
          { response
            this.polls = response.data.response.results;
            var eventTitle = response.data.response.event_title;
            var panelTitle = response.data.response.panel_title;
            this.subheading = "Събитие \"" + eventTitle + "\" > Панел \"" + panelTitle + "\"";
            this.showLoading = false;
          },
          error => {
            this.showLoading = false;
            this.handleError(error);
          })
    },

    handleError(error)
    {
      let errorMsg =
          error.message || (error.response && error.response.data && error.response.data.message) ||
          error.toString();
      if(error.response.data.error.message)
      {
        swal("Грешка", error.response.data.error.message, "error");
      }
      if (error.response && error.response.status === 500)
      {
        console.log(errorMsg)
      }
    },

    getStatusColor(status)
    {
      if(this.statusColors[status])
      {
        return this.statusColors[status];
      }
    },
  },
}
</script>